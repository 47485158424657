import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { getRoundedStarRating } from "utils/rating";
import { Text } from "components/core/Typography";
import Star from "./Star";

import "./StarRating.scss";

function StarRating({ className, outOf, rating, withLabel }) {
	return (
		<div className={classnames(className, "star-rating")}>
			<span>
				{Array(outOf)
					.fill(0)
					.map((_, i) => (
						<Star
							key={`star-rating-${rating - i}`}
							value={getRoundedStarRating(rating - i)}
						/>
					))}
			</span>
			{withLabel && (
				<Text
					last
					size="small"
					color="blue"
					weight="extraBold"
				>{`${rating} out of ${outOf} stars`}</Text>
			)}
		</div>
	);
}

StarRating.propTypes = {
	className: PropTypes.string,
	outOf: PropTypes.number,
	rating: PropTypes.number,
	withLabel: PropTypes.bool
};

StarRating.defaultProps = {
	withLabel: false,
	rating: 0,
	outOf: 5
};

export default StarRating;
