import React from "react";
import PropTypes from "prop-types";

export default function Star({ value }) {
	return (
		<svg
			className="svg star"
			width="13"
			height="13"
			viewBox="0 0 13 13"
			xmlns="http://www.w3.org/2000/svg"
		>
			{value > 0 && value < 1 ? (
				<>
					<path
						// eslint-disable-next-line max-len
						d="M6.5 0l1.46 4.491h4.722l-3.82 2.776 1.459 4.492L6.5 8.983l-3.82 2.776 1.459-4.492-3.82-2.776H5.04L6.5 0z"
						fill="#A9A8A8"
						data-testid="half-star"
					/>
					<g clipPath="url(#clip0_3534_263830)">
						<path
							// eslint-disable-next-line max-len
							d="M6.5 0l1.46 4.491h4.722l-3.82 2.776 1.459 4.492L6.5 8.983l-3.82 2.776 1.459-4.492-3.82-2.776H5.04L6.5 0z"
							fill="#1C72E7"
						/>
					</g>
					<defs>
						<clipPath id="clip0_3534_263830">
							<path
								fill="#fff"
								d="M0 0H6.5V13H0z"
							/>
						</clipPath>
					</defs>
				</>
			) : (
				<path
					// eslint-disable-next-line max-len
					d="M6.5 0l1.46 4.491h4.722l-3.82 2.776 1.459 4.492L6.5 8.983l-3.82 2.776 1.459-4.492-3.82-2.776H5.04L6.5 0z"
					fill={value >= 1 ? "#1C72E7" : "#A9A8A8"}
					data-testid={value >= 1 ? "blue-star" : "gray-star"}
				/>
			)}
		</svg>
	);
}

Star.propTypes = {
	value: PropTypes.number
};

Star.defaultProps = {
	value: 1
};
