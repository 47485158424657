import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useScrollToTop } from "@sparefoot/react-commons/hooks";
import { Text } from "components/core/Typography";
import { Button } from "components/core/Button";

import "./ScrollToTop.scss";

function ScrollToTop({ className, segmentLabel }) {
	const [handleClick, isVisible] = useScrollToTop();
	return (
		<Button
			className={classnames("sf-scroll-to-top", className, {
				visible: isVisible
			})}
			segmentLabel={segmentLabel}
			onClick={handleClick}
			fullWidth={false}
		>
			<Text
				color="blue"
				weight="bold"
				last
			>
				Scroll to Top
			</Text>
		</Button>
	);
}

ScrollToTop.defaultProps = {
	segmentLabel: "scrollToTop"
};

ScrollToTop.propTypes = {
	className: PropTypes.string,
	segmentLabel: PropTypes.string
};

export default ScrollToTop;
