import React from "react";
import PropTypes from "prop-types";

import { Link } from "components/core/Link";
import { StarRating } from "../StarRating";

import "./StarReviewsCount.scss";

const StarReviewsCount = ({ number, average, url = "" }) => (
	<div className="reviews-count">
		<StarRating
			rating={average}
			outOf={5}
		/>
		<Link
			nowrap
			href={url}
			className="number"
			hover
			size="small"
			weight="bold"
			color="lightGray"
			underline
			segmentLabel="reviews"
		>
			{`${number} reviews`}
		</Link>
	</div>
);

StarReviewsCount.propTypes = {
	url: PropTypes.string,
	number: PropTypes.number,
	average: PropTypes.number
};

export default StarReviewsCount;
